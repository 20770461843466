import React from "react"

import Index from "../components/index"
import Seo from "../components/other/seo"
import Container from "../components/layout/container";
import PageHeader from "../components/elements/PageHeader";
import ButtonLink from "../components/elements/ButtonLink";
import InsightsStudyInfographic from "../components/images/InsightsStudyInfographic";
import InsightsLogo from "../components/images/InsightsLogo";

const Insights = () => (
  <Index>
    <Seo title="INSIGHTS Paper" />
    <PageHeader>
      <h1>Insights Paper</h1>
      <h2>The Largest Data-driven IG Study</h2>
      <p className="no-margins">
        <ButtonLink href="/request-demo" text="Schedule a Demo" type="primary" />
      </p>
    </PageHeader>
    <Container id="insights-logo-wrap" extraContainerClasses="align-center">
      <InsightsLogo />
    </Container>
    <Container id="insights-study-wrap" extraContainerClasses="align-center">
      <h2 className="text-blue">INSIGHTS Study Exposes Inaccuracies</h2>
      <p className="text-gray">
        InCircle’s unique approach is backed by INSIGHTS, the largest data-driven IG study to date. The <br className="hide-medium" />
        study was overseen by eight physicians with expertise in neuromuscular medicine who <br className="hide-medium" />
        independently reviewed all written notes, lab data, imaging reports and electrodiagnostic studies <br className="hide-medium" />
        supplied to insurance companies to secure IG coverage approval for 248 patients. Patients were <br className="hide-medium" />
        referred for IG therapy by 154 physicians in 26 states, and the findings were telling:
      </p>
      <InsightsStudyInfographic />
    </Container>
    <Container extraContainerClasses="align-center space-out">
      <h2 className="text-blue">Data Supports New Prescribing Model</h2>
      <p className="text-gray">
        InCircle combines the knowledge of key opinion leaders with the data needed to make the right <br className="hide-medium" />
        prescribing decision. This model can not only lower the rates of misdiagnosis and the <br className="hide-medium" />
        overutilization of IG, it can also be instrumental in minimizing waste and lowering costs.
      </p>
    </Container>
    <Container id="incircle-participant-benefits" extraInnerClasses="banner-with-bg">
      <h2>InCircle Review Benefits</h2>
      <p>
        InCircle will provide access to the data points that led to the decision, <br className="hide-medium" />
        along with a summary of findings to help educate the healthcare <br className="hide-medium" />
        provider, the health insurer and the patient.
      </p>
      <p>
        <ButtonLink href="/request-demo/" text="Schedule a Free Demo Today!" type="primary" />
      </p>
    </Container>
  </Index>
)

export default Insights
